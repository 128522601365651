var menuEl = $("#j-menu");
var menuBtnEl = $("#j-menu-btn");
var fixedEl = $("#j-fixed");
var topEl = $("#j-top");

$(window).on("scroll", listenScroll);
listenScroll();
function listenScroll() {
  var windowHeight = $(window).height();
  var scrollTop = $(window).scrollTop();

  $(".inner").each(function() {
    var itemTop = $(this).offset().top;
    if (itemTop < windowHeight + scrollTop) {
      $(this).addClass("animate-enter");
    }
  });
}

// var navs = $(".m-nav a");

// navs.each(function() {
//   $(this).on("click", function(e) {
//     e.preventDefault();
//     $.scrollTo($(this).attr("href"));
//   });
// });

$(document).on("scroll", setClass);
setClass();
function setClass() {
  if (!!document.documentElement.scrollTop) {
    fixedEl.addClass("m-head--active");
    topEl.show();
  } else {
    fixedEl.removeClass("m-head--active");
    topEl.hide();
  }
}
menuBtnEl.on("click", function() {
  menuEl.toggleClass("menu--active");
  menuBtnEl.toggleClass("btn--active");
});
topEl.toTop({
  autohide: true,
  offset: 420,
  speed: 500,
  position: true,
  right: 30,
  bottom: 30
});
